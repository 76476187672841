<template>
  <div class="m-5">
    <div class="about">
      <h1 v-if="$root.$i18n.locale == 'ar'" class="mb-5">
        {{ item.name.ar }}
      </h1>
      <h1 v-if="$root.$i18n.locale == 'en'" class="mb-5">
        {{item.name.en }}
      </h1>
      <div style="word-wrap: break-word;" v-if="$root.$i18n.locale == 'ar'" class="text-body-2" v-html="item.body.ar"></div>
      <div style="word-wrap: break-word;" v-if="$root.$i18n.locale == 'en'" class="text-body-2" v-html="item.body.en"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      item: {
        name: {
          ar: null,
          en: null,
        },
        body: {
          ar: null,
          en: null,
        },
      },
      slug: null,
    };
  },
  created() {
    this.slug = this.$route.params.slug;
    this.$http
      .post("slug/getBySlug/", {
        slug: this.slug,
      })
      .then((res) => {
        this.item = res.data.docs.length > 0 ? res.data.docs[0] : {};

        
      });
  },
  watch: {
    "$route.params.slug"(val) {
      this.slug = val;
      this.$http
        .post("slug/getBySlug/", {
          slug: this.slug,
        })
        .then((res) => {
          this.item = res.data.docs.length > 0 ? res.data.docs[0] : {};
        });
    },
  },
};
</script>

<style>
.about {
  background: #f4f6f5;
  border-radius: 9px;
  padding: 39px;
}

.about h1 {
  color: #313131cc;
  font-size: 18px;
  font-weight: 500;
  line-height: 32px;
}
.about .text-body-2 {
  padding-top: 42px;
  border-top: 1px solid #e9eaec;
  font-size: 16px;
  color: #313131b2;
}
</style>
